/* eslint-disable */
import axios from "@axios";
import { HTTP } from "@/libs/axios";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler.js";

const state = () => {
  return {
    userRoles: null,
    admins: null,
    userBranches: null,
  };
};

const getters = {};

const mutations = {
  SET_USER_ROLES(state, response) {
    let data;
    if (response.data) {
      data = response.data.data.roles;
      state.userRoles = data.map((obj) => ({
        roleId: obj.id,
        text: obj.name,
        value: obj.name,
        isEnabled: obj.isEnabled,
      }));
    } else {
      state.userRoles = null;
    }
  },
  SET_USER_BRANCHES(state, response) {
    state.userBranches = response.data.data.vendorUsers;
  },
  SET_USERS(state, response) {
    state.admins = response.data.vendorUsers;
  },
  CLEAR_USER_ROLES(state) {
    state.userRoles = null;
  },
};
const actions = {
  async getUserRole({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/role-management/vendor-users/${payload.id}/roles`, {
        email: payload.email,
      })
        .then((response) => {
          commit("SET_USER_ROLES", response);
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async setUserRole(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/role-management/vendor-users/${payload.id}/roles`, {
        roles: payload.roles,
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else successMessage("Successfully", "User Roles Saved");
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getAdmins({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/role-management/vendor-users`)
        .then((response) => {
          commit("SET_USERS", response.data);
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getUserBranches({ commit }, id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/vendor-users-roles/vendor-users/${id}/branches`)
        .then((response) => {
          commit("SET_USER_BRANCHES", response);
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async setUserBranches({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        `/vendor-users-roles/vendor-users/${payload.id}/branches`,
        payload
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
