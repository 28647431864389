/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		productCategories: [],
		errors: {
			mobile: null,
			email: null,
			idCode: null,
		},
		verificationStatus: null,
		verification: {
			error: null,
		},
		verificationEmail: null,
	};
};

const getters = {};

const mutations = {
	SET_PRODUCT_CATEGORIES(state, response) {
		console.log(response);
		const payload = response.data.productCategories.map((product) => ({
			id: product.productCategoryId,
			label:
				product.productCategoryNameGeo + ' / ' + product.productCategoryNameEng,
			children: product.subCategories.map((subCategory) => ({
				categoryId: product.productCategoryId,
				subCategoryId: subCategory.productSubCategoryId,
				label:
					subCategory.productSubCategoryNameGeo +
					' / ' +
					subCategory.productSubCategoryNameEng,
			})),
		}));
		state.productCategories = payload;
	},
	SET_EMAIL_ERROR(state, response) {
		let data = response;
		state.errors.email = data;
	},
	SET_VERIFICATION(state, response) {
		let data = response;
		state.verificationStatus = data;
	},
	SET_VERIFICATION_EMAIL(state, response) {
		let data = response;
		state.verificationEmail = data;
	},
	SET_VERIFICATION_IDENTIFIER(state, response) {
		let data = response;
		state.verification = data;
	},
	SET_MOBILE_ERROR(state, response) {
		let data = response;
		state.errors.mobile = data;
	},
	SET_ID_ERROR(state, response) {
		let data = response;
		state.errors.idCode = data;
	},
	CLEAR_ERRORS(state) {
		state.errors = {
			mobile: null,
			email: null,
			idCode: null,
		};
	},
};

const actions = {
	async vendorRegistration(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(`/registration-service/vendors`, {
				identificationCode: payload.identificationCode,
				email: payload.email,
				isIndividualEntrepreneur: payload.isIndividualEntrepreneur,
				mobile: payload.mobile,
				password: payload.password,
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Vendor Registered');
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async getProductCategories({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/registration-service/product-categories`)
				.then((response) => {
					commit('SET_PRODUCT_CATEGORIES', response.data);
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	clearErrors({ commit }) {
		commit('CLEAR_ERRORS');
	},
	async mobileVerificationCode({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/verification/mobile-verification-code`)
				.then((response) => {
					commit('SET_VERIFICATION_IDENTIFIER', response.data);
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async mobileVerification({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(`/verification/verify-mobile`, {
				identifier: payload.identifier,
				code: payload.code,
			})
				.then((response) => {
					commit('SET_VERIFICATION', response.data);
					if (response.data.error) errorsHandler(response.data.error);
					else if (response.data.isSuccessfullyVerified === false)
						errorsHandler(response.data.error);
					else successMessage('Successfully', 'Mobile Verified');
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async mobileCheck({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/registration-service/mobile-check/${payload}`)
				.then((response) => {
					commit('SET_MOBILE_ERROR', response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async emailVerificationLink({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/verification/email-verification-link`)
				.then((response) => {
					commit('SET_VERIFICATION_EMAIL', response.data);
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async emailCheck({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/registration-service/email-check/${payload}`)
				.then((response) => {
					commit('SET_EMAIL_ERROR', response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async identificationCodeCheck({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/registration-service/identificationCode-check/${payload}`)
				.then((response) => {
					commit('SET_ID_ERROR', response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
