/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		userApplication: null,
		userData: null,
		showLoading: false,
		contractStatus: null,
		contractData: null,
	};
};

const getters = {};

const mutations = {
	SET_USER_INFO(state, response) {
		state.userData = response;
	},
	SET_USER_APPLICATION(state, response) {
		state.userApplication = response.data;
	},
	SET_SHOW_LOADING(state, response) {
		state.showLoading = response;
	},
	SET_CONTRACT_STATUS(state, response) {
		state.contractStatus = response.data.data.status;
	},
	SET_CONTRACT(state, response) {
		state.contractData = response.data.data;
	},
};

const actions = {
	async getUserInfo({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/profile/info`)
				.then((response) => {
					commit('SET_USER_INFO', response.data);
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async getUserInfo({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/profile/info`)
				.then((response) => {
					commit('SET_USER_INFO', response.data);
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async vendorApplication({ commit }, payload) {
		commit('SET_SHOW_LOADING', true);
		return new Promise((resolve, reject) => {
			if (payload.create) {
				HTTP.post(`/profile/application`, payload.formData, {
					header: { 'Content-Type': 'multipart/form-data' },
				})
					.then((response) => {
						if (response.data.error) errorsHandler(response.data.error);
						else successMessage('Successfully', 'Application Created');
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					})
					.finally(() => commit('SET_SHOW_LOADING', false));
			} else {
				HTTP.put(`/profile/application`, payload.formData, {
					header: { 'Content-Type': 'multipart/form-data' },
				})
					.then((response) => {
						if (response.data.error) errorsHandler(response.data.error);
						else successMessage('Successfully', 'Application Updated');
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					})
					.finally(() => commit('SET_SHOW_LOADING', false));
			}
		});
	},
	async getUserApplication({ commit }, _) {
		commit('SET_SHOW_LOADING', true);
		return new Promise((resolve, reject) => {
			HTTP.get(`/profile/info/application`)
				.then((response) => {
					commit('SET_USER_APPLICATION', response.data);
					resolve(response);
					commit('SET_SHOW_LOADING', false);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async updateUserInfo({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(`/profile/application`, {
				userName: payload.userName,
				email: payload.email,
				mobile: payload.mobile,
			})
				.then((response) => {
					commit('SET_USER_INFO', response.data.data.info);
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'User Updated');
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async getContractStatus({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/profile/contractAttachment/status`)
				.then((response) => {
					console.log('contract status', response);
					commit('SET_CONTRACT_STATUS', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async getContract({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/profile/contractAttachment`)
				.then((response) => {
					commit('SET_CONTRACT', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
	async uploadContract(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(`/profile/contractAttachment`, payload, {
				header: { 'Content-Type': 'multipart/form-data' },
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Uploaded Contract');
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
