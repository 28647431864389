export default [
  {
    path: "/",
    name: "home",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/Home.vue"),
    meta: {
      pageTitle: "Home",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/login",
    name: "login",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/account-setting",
    name: "account-setting",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/account-setting/AccountSetting.vue"),
    meta: {
      emailVerified: true,
      pageTitle: "Account Settings",
      breadcrumb: [
        {
          text: "Account Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/admin-management",
    name: "admin-management",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/user-management/UserManagement.vue"),
    meta: {
      emailVerified: true,
      pageTitle: "Admin Management",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/role-management",
    name: "role-management",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/role-management/RoleManagement.vue"),
    meta: {
      emailVerified: true,
      pageTitle: "Role Management",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/vendor-users",
    name: "vendor-users",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/role-management/VendorUsers.vue"),
    meta: {
      emailVerified: true,
      pageTitle: "Vendor Users",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/register-product",
    name: "registerProduct",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/RegisterProduct.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Register Product",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/approved-produccts",
    name: "approved-products",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/ApprovedProducts.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Approved Products",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/copy-product/:id/:isApproved?",
    name: "copyProduct",
    // eslint-disable-next-line import/no-unresolved
    component: () => import("@/views/products/CopyProduct.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Copy Product",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-product/:id/:isApproved?",
    name: "editProduct",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/EditProduct.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Edit Product",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pending-products",
    name: "pending-products",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/products/Products.vue"),
    meta: {
      emailVerified: false,
      pageTitle: "Pending Products",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/register-vendor",
    name: "vendor-register",
    // eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
    component: () => import("@/views/vendor-registration/VendorRegister.vue"),
    meta: {
      emailVerified: false,
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/verification/email/fail",
    name: "failure",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/status-pages/failurePage.vue"),
    meta: {
      layout: "full",
      isStatusPage: true,
    },
  },
  {
    path: "/verification/email/success",
    name: "success",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/status-pages/successPage.vue"),
    meta: {
      layout: "full",
      isStatusPage: true,
    },
  },
  {
    path: "/password/set",
    name: "success",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/password-set/PasswordSet.vue"),
    meta: {
      layout: "full",
      isStatusPage: true,
    },
  },
  {
    path: "/redirect",
    name: "redirectadmin",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/impersonation/Impersonation.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];
